
import { defineComponent } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import { useResetPasswordForm } from '@/views/resetPassword/hooks/use-reset-password-form';
import cButton from '@/components/common/button/button.component.vue';
import iconUser from '@/components/icons/icon-user.component.vue';
import CLogo from '@/components/common/logo/logo.component.vue';

export default defineComponent({
  name: 'CLoginForm',
  components: {
    CLogo,
    iconUser,
    cButton,
  },
  setup() {
    const {
      onSubmit,
      resetForm,
      // disabledButton,
      tokenSent,
    } = useResetPasswordForm();

    return {
      tokenSent,
      onSubmit,
      resetForm,
      // disabledButton,
      useTranslations,
    };
  },
});
