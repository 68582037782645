import { reactive, ref, watch } from 'vue';
import { getUrl } from '@/service/service.helpers';
import axios from 'axios';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';
import { name as vResetPassword } from '@/views/resetPassword/reset-password.route';

export const useResetPasswordForm = () => {

  const resetForm = reactive({
    email: '',
    repeatEmail: ''
  })

  const disabledButton = ref(true)

  const tokenSent = ref(false)

  watch(() => resetForm.email, (newValue) => {
    disabledButton.value = newValue !== resetForm.repeatEmail;
  })

  watch(() => resetForm.repeatEmail, (newValue) => {
    disabledButton.value = newValue !== resetForm.email;
  })

  const onSubmit = async () => {
    // if(resetForm.email === resetForm.repeatEmail) {
    try {
      await axios.get(getUrl('/users/reset-password'), {
        params: {
          email: resetForm.email
        }
      })
      tokenSent.value = true
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t(`${vResetPassword}.form.success.sentLink`)
      );
    } catch (e) {
      throw Error(e);
    }
    // }
  }

  return {
    disabledButton,
    resetForm,
    onSubmit,
    tokenSent
  };
};
